export const columns = [
  {
    title: 'Effizient & einfach',
    text: 'payactive unterstützt dich bei der automatisierten Rechnungsstellung für deine Abo- und Einmalzahlungen. Ob Fitness-Studio, Coworkingspace oder Versicherung: deine wiederkehrende Rechnungsstellung war noch nie so einfach und effizient wie mit payactive.',
  },
  {
    title: 'Reibungslos & smart',
    text: 'Vom digitalen (Lastschrift-) Onboarding über die Zahlungsaufforderung bis hin zum smarten Steuern von Zahlungsausfällen - alles auf einer Plattform. Schnelle Liquditiät, wenigere Zahlungsausfälle und Kund:innen, die gerne wieder zahlen.',
  },
  {
    title: 'Nachhaltig & verantwortungsvoll',
    text: 'Lass deine Kund:innen mit jeder Zahlung ein soziales oder ökologisches Projekt ihrer Wahl unterstützen - ohne Mehrkosten für dich und deine Kund:innen. Mit Social Cashback steigerst du automatisch deine Kundenbindung und Corporate-Social-Responsibility - für eine nachhaltige Zukunft.',
  },
]

export const columns2 = [
  {
    title: 'Automatisiert + effizient',
    text: 'payactive automatisiert, personalisiert und verarbeitet wiederkehrende Zahlungsprozesse wie Überweisungen und Lastschriften. Basierend auf der Open-Banking-Infrastruktur schaffen unsere Zahlungsprozesse völlig reibungslos neue Möglichkeiten der Wertschöpfung für Unternehmen und ihre Kund:innen.',
  },
  {
    title: 'Einfach + smart',
    text: 'payactive lässt sich einfach in digitale Ökosysteme (z.B. ERPs und CRMs) integrieren. Mit Hilfe der Künstlichen Intelligenz PIA (Payment Intelligence Assistant) optimieren wir Zahlungsprozesse, die für Unternehmen und ihre Kund:innen echten Erfolg dank Informationsvorsprung bedeuten.',
  },
  {
    title: 'Inklusiv + nachhaltig',
    text: 'Als Unternehmen im Verantwortungseigentum haben wir Nachhaltigkeit zu unserer Unternehmensmission gemacht. Unsere Kund:innen binden wir aktiv in diese Mission ein. Denn mit jeder Zahlungstransaktion über payactive können sie soziale oder ökologische Projekte finanziell unterstützen - ganz einfach und ohne Mehrkosten.',
  },
]
