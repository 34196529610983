import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { graphql, useStaticQuery } from 'gatsby'
import Theme from '../components/theme'
import {
  SEO,
  Layout,
  SectionHero,
  SectionRecentPosts,
  SectionTeam,
  SectionText,
  SectionTextImage,
  SectionColumns,
} from '../components'
import { SectionNewsletter } from '../components/Section/SectionNewsletter'
import { team } from '../data/static/team'
import { columns2 } from '../data/static/columns'

// import arrows from '../data/animations/pfeile.json'
// import sustainability from '../data/animations/sustainability.json'
// import heart from '../data/animations/nachhaltigesherz.json'
// import effizienz from '../data/animations/effizienz.json'

const CompanyPage = (): React.ReactElement => {
  const {
    wpPage: {
      seo: { title, metaDesc, opengraphImage },
    },
  } = useStaticQuery(
    graphql`
      {
        wpPage(id: { eq: "cG9zdDozMDM=" }) {
          seo {
            title
            metaDesc
            opengraphImage {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    `
  )

  return (
    <Theme>
      <SEO
        wordpressTitle={title}
        description={metaDesc}
        socialImageRelativeUrl={opengraphImage?.localFile.publicURL}
      />

      <div className="fixed z-0 w-full h-full top-0 left-0 opacity-10">
        <StaticImage
          src="../images/fish-2.jpg"
          alt="Fische"
          objectFit="cover"
          className="w-full h-full select-none"
          draggable={false}
        />
      </div>
      <Layout>
        <SectionHero
          title="Wir sind payactive"
          titleTag="h1"
          subtitle="Die smarte Zahlungsplattform mit nachhaltigem Herzen"
          text={`Als Startup aus dem Fintech-Sektor hilft dir payactive bei der Digitalisierung und Integration intelligenterer Zahlungsprozesse. payactives Payment-Plattform bringt dabei vier smarte und nachhaltige wirkende Finanz-Services in einem starken Konzept zusammen.
          \n Was uns als junges Unternehmen allerdings besonders spannend macht, ist, dass wir neu denken: 
          \n Von den Brüdern Mathias und Manuel Born im Verantwortungseigentum gegründet, stellt payactive eine neue Generation Startup dar: Nämlich eines, das ein langfristig aufgestelltes, nachhaltig wirkendes Unternehmen dem schnellen Exit vorzieht. Lerne uns kennen.`}
        />

        <SectionText containerClass="py-0" title="So arbeiten wir" />
        <SectionText
          containerClass="py-0"
          title="Verantwortung für unsere 
            Kund:innen und unsere Welt"
          headlineVariant="clean"
        />

        <SectionColumns data={columns2} />

        {/* Team Reel */}
        <SectionText
          containerId="team"
          title="Team payactive"
          subtitle="payactive gehört unserem Team."
        />
        <SectionTeam containerClass="py-0" data={team} />

        <SectionTextImage
          // containerClass="pb-14"
          title="Join the team!"
          text="payactive sucht immer nach neuen Kolleg:innen. Hast du Lust? Wir freuen uns auf deine Bewerbung."
          actionLabel="Initiativ bewerben"
          actionLinks={[
            {
              url: 'mailto:hr@payactive.eu',
              label: 'Initiativ bewerben',
              external: true,
            },
          ]}
          image={
            <StaticImage
              src="../images/jointheteam.jpg"
              alt="Initiativ bewerben"
              className="rounded-lg"
            />
          }
        />

        <SectionText
          title="News"
          // subtitle="Was gerade bei payactive passiert"
          containerClass="pt-14"
          headlineHighlighted
        />

        <SectionRecentPosts />

        <SectionTextImage
          title="Presseanfrage"
          text="payactive freut sich immer über Presseanfragen. Kontaktieren Sie uns einfach und wir melden uns zeitnah bei Ihnen."
          actionLabel="Direkt kontaktieren"
          actionLinks={[
            {
              url: 'mailto:presse@payactive.eu',
              label: 'Direkt kontaktieren',
              external: true,
            },
          ]}
          image={
            <StaticImage
              src="../images/woman.jpg"
              alt="Frau"
              className="rounded-lg"
            />
          }
        />

        {/* <SectionText
        title="payper"
        subtitle="Nachhaltige payactive News per E-Mail"
        headlineHighlighted={false}
        containerClass="pt-14 pb-7"
      /> */}
        <SectionNewsletter containerClass="" page="Unternehmen" />
      </Layout>
    </Theme>
  )
}

export default CompanyPage
