import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { TeamMemberType } from '../../types'

export const team: TeamMemberType[] = [
  {
    name: 'Mathias Born',
    position: 'CEO | Product, Finance',
    image: (
      <StaticImage
        src="../../images/team/mathias.png"
        alt="Mathias Born"
        className="w-44"
      />
    ),
  },
  {
    name: 'Manuel Born',
    position: 'COO | Design, HR, Social Cashback',
    image: (
      <StaticImage
        src="../../images/team/manuel.png"
        alt="Manuel Born"
        className="w-44"
      />
    ),
  },
  {
    name: 'Victoria Petrone',
    position: 'Junior Software Engineer',
    image: (
      <StaticImage
        src="../../images/team/victoria.png"
        alt="Victoria Petrone"
        className="w-44"
      />
    ),
  },

  {
    name: 'Adrian Kodja',
    position: 'Software Engineer',
    image: (
      <StaticImage
        src="../../images/team/adrian.png"
        alt="Adrian Kodja"
        className="w-44"
      />
    ),
  },
  {
    name: 'Andreas Finke',
    position: 'CTO (interim)',
    image: (
      <StaticImage
        src="../../images/team/andreas.png"
        alt="Andreas Finke"
        className="w-44"
      />
    ),
  },
  {
    name: 'Gerald Hensel',
    position: 'CMO | Brand & Communications (interim)',
    image: (
      <StaticImage
        src="../../images/team/gerald.png"
        alt="Gerald Hensel"
        className="w-44"
      />
    ),
  },

  {
    name: 'Arbnora Kodja',
    position: 'Junior Software Engineer',
    image: (
      <StaticImage
        src="../../images/team/nora.png"
        alt="Arbnora Kodjar"
        className="w-44"
      />
    ),
  },
  {
    name: 'Johanna Siol',
    position: 'Werksstudentin Marketing',
    image: (
      <StaticImage
        src="../../images/team/johanna.png"
        alt="Johanna Siol"
        className="w-44"
      />
    ),
  },
  {
    name: 'Jeremias Endres',
    position: 'Payactivist supporter',
    image: (
      <StaticImage
        src="../../images/team/jeremias.png"
        alt="Jeremias Endres"
        className="w-44"
      />
    ),
  },
]
